import React, { useEffect, useRef, useState } from "react";
import pin from "../../assets/images/searchlogo.png";
import search from "../../assets/images/search-icon.png";
import directions from "../../assets/images/direction-icon.png";
import "./autocomplete.scss";
import close from "../../assets/images/remove.png";
import Searchpoint from "../searchedplaces/Searchpoint";
import { Col, Row, Offcanvas } from "react-bootstrap";
import location from "../../assets/images/searchpin.png";
import { imageUrl } from "../../urls/urls";
import Direction from "../directions/Direction";
import {
  autoCompleteFetchDatas,
  departmentExists,
  fetchAllDepartmentResult,
} from "../apis/api";
import menu from "../../assets/images/menu(1).png";
import logo_2 from "../../assets/images/logo_2.png";
import { Link } from "react-router-dom";
import { privacyUrl, termUrl } from "../../urls/urls";
import { useMapContext } from "../context/Mapcontext";
import back from "../../assets/images/back.png";
import { useMap } from "react-map-gl/maplibre";
import Departmentlist from "../departmentlist/Departmentlist";

const Autocomplete = ({ settingZoomLevels }) => {
  const [word, setWord] = useState("");
  const [data, setData] = useState([]);
  const [selectedWord, setSelectedWord] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const { isBooleanValue, setBooleanValue } = useMapContext();
  const { amenityWord, setAmenityValue } = useMapContext();
  const {
    direction,
    setDirectionValue,
    list,
    setListValue,
    setMapCoordinates,
  } = useMapContext();
  const [departmentList, setDepartmentList] = useState([]);
  const [minLatBound, setMinLatBound] = useState();
  const [minLngBound, setMinLngBound] = useState();
  const [maxLatBound, setMaxLatBound] = useState();
  const [maxLngBound, setMaxLngBound] = useState();

  const { current: map } = useMap();

  useEffect(() => {
    if (map) {
      const updateBounds = () => {
        const bounds = map.getBounds();
        const topRight = bounds.getNorthEast();
        const bottomLeft = bounds.getSouthWest();

        setMinLatBound(bottomLeft.lat);
        setMinLngBound(bottomLeft.lng);
        setMaxLatBound(topRight.lat);
        setMaxLngBound(topRight.lng);
      };
      map.on("moveend", updateBounds);

      updateBounds();
      return () => {
        map.off("moveend", updateBounds);
      };
    }
  }, [map]);

  useEffect(() => {
    if (amenityWord !== "") {
      setWord("");
      setSelectedWord(null);
      setData([]);
    }
    if (list === true) {
      setWord("");
      setSelectedWord(null);
      setData([]);
    }
  }, [amenityWord, isBooleanValue, list]);

  useEffect(() => {
    // Check if geolocation is supported by the browser
    if ("geolocation" in navigator) {
      // Prompt user for permission to access their location
      navigator.geolocation.getCurrentPosition(
        // Success callback function
        (position) => {
          // Get the user's latitude and longitude coordinates
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        // Error callback function
        (error) => {
          // Handle errors, e.g. user denied location sharing permissions
          console.error("Error getting user location:", error);
        }
      );
    } else {
      // Geolocation is not supported by the browser
      console.error("Geolocation is not supported by this browser.");
    }
  }, []); // Run this effect only once when the component mounts

  const autoCompleteFetchData = async (textWord) => {
    let name = await departmentExists(textWord);
    let tempArray = [];
    if (data) {
      tempArray.push({ department: name });
    }
    let datas = null;
    if (latitude !== null && longitude !== null) {
      datas = await autoCompleteFetchDatas(textWord, latitude, longitude);
    } else if (latitude === null && longitude === null) {
      datas = await autoCompleteFetchDatas(textWord, 27.677106, 85.32239);
      // If data is present, set the boolean value to true
    }
    tempArray.push({ autocomplete: datas });
    setData(tempArray);
  };

  const [showDiv, setShowDiv] = useState(false);

  // Function to handle button click
  const handleButtonClick = () => {
    setShowDiv(!showDiv);
  };

  // const [direction, setDirection] = useState(false);

  const handleName = (name) => {
    const encodedName = encodeURIComponent(name);
    setWord(name);
    setSelectedWord(encodedName);
    setData([]);
    setBooleanValue(!isBooleanValue);
    setListValue(false);
  };

  const handleClick = () => {
    setDirectionValue(!direction);
    setWord("");
    setSelectedWord("");
    setData([]);
    setBooleanValue(!isBooleanValue);
    setAmenityValue("");
  };

  const handleClear = () => {
    if (departmentList && isBooleanValue) {
      setBooleanValue(isBooleanValue);
      setDepartmentList([]);
    }
    if (isBooleanValue) {
      setBooleanValue(!isBooleanValue);
    }
    if (word) {
      setWord("");
      setSelectedWord("");
      setData([]);
      return;
    }
    if (word && !direction) {
      setWord("");
      setSelectedWord("");
      setData([]);
      setDirectionValue(!direction);
      setBooleanValue(isBooleanValue);
      return;
    }
    if (word && amenityWord !== "") {
      setWord("");
      setSelectedWord("");
      setAmenityValue("");
      setData([]);
    }
    if (amenityWord !== "") {
      // Clear all values without considering direction
      setAmenityValue("");
      setWord("");
      setSelectedWord("");
      setData([]);
    } else {
      // If amenityValue is empty, toggle direction
      setDirectionValue((prevDirection) => !prevDirection);
    }

    setBooleanValue(!isBooleanValue);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleBack = () => {
    setDirectionValue(!direction);
  };

  const searchIconClickedHandler = (searchItem) => {
    setSelectedWord(searchItem);
    setWord(searchItem);
    setData([]);
    setBooleanValue(!isBooleanValue);
    setListValue(false);
  };

  const handleDepartmentClick = async (departmentName) => {
    try {
      const list = await fetchAllDepartmentResult(
        departmentName,
        latitude,
        longitude,
        minLatBound,
        minLngBound,
        maxLatBound,
        maxLngBound
      );

      if (list && list.data && Array.isArray(list.data.features)) {
        const departmentList = list.data.features;
        // setData([]);
        setDepartmentList(departmentList);
      } else {
        console.error("Invalid data structure:", list.data);
      }
    } catch (error) {
      console.error("Error fetching department results:", error);
    }
  };

  const autoCompleteLists = (word, data) => {
    if (word.length > 2) {
      return (
        <>
          {word && data && data.length > 0 ? (
            <div className="search-data">
              <ul>
                {data[0]?.department?.name && (
                  <li
                    onClick={() => {
                      handleDepartmentClick(data[0]?.department?.name);
                    }}
                  >
                    <Row
                      style={{
                        display: "flex",
                        paddingBottom: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <Col
                        md={2}
                        xs={3}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {data[0]?.department?.logo ? (
                          <img
                            src={`${imageUrl}${data[0]?.department?.logo}`}
                            alt={`${data[0]?.department?.name}`}
                          />
                        ) : (
                          <img src={location} alt="logo" />
                        )}
                      </Col>
                      <Col
                        md={10}
                        xs={9}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {data[0]?.department?.name} &nbsp;
                          {data[0]?.department?.name
                            ? "(See all locations)"
                            : null}
                        </span>
                      </Col>
                    </Row>
                  </li>
                )}
                {data[1]?.autocomplete?.slice(0, 10).map((item, index) => {
                  return (
                    <li
                      className="searchautocomplete"
                      key={index.toString()}
                      onClick={() => {
                        handleName(item.name);
                        handleButtonClick();
                      }}
                    >
                      <Row
                        style={{
                          display: "flex",
                          paddingBottom: "30px",
                          cursor: "pointer",
                        }}
                      >
                        <Col
                          md={2}
                          xs={3}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {item.logo ? (
                            <img
                              src={`${imageUrl}${
                                JSON.parse(item.logo)[0].image
                              }`}
                              alt={`${item.name}`}
                            />
                          ) : (
                            <img src={location} alt="logo" />
                          )}
                        </Col>
                        <Col
                          md={10}
                          xs={9}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>{item.name}</span>
                        </Col>
                      </Row>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  };

  const [selectedItem, setSelectedItem] = useState(null);

  const handleListClick = (properties, geometry) => {
    if (properties && geometry) {
      setMapCoordinates(geometry.coordinates[1], geometry.coordinates[0]);
      map.flyTo({
        center: geometry.coordinates,
        essential: true,
        zoom: 20,
        speed: 1,
        curve: 1,
        duration: 2000,
      });
      setSelectedItem({ properties, geometry });
    }
  };

  return (
    <>
      <div className="autocomplete-search">
        <div className="search-box">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 6px",
            }}
          >
            <Col
              xs={1}
              sm={1}
              md={1}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {amenityWord && direction ? (
                <img
                  src={back}
                  alt="back"
                  onClick={() => {
                    handleBack();
                  }}
                />
              ) : (
                <img src={pin} alt="pin" />
              )}

              {/* <Button onClick={handleShow}> */}
              {/* <img src={menu} alt="menu" onClick={handleShow} /> */}
              {/* </Button> */}

              <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>
                    <img
                      src={logo_2}
                      style={{ height: "40px", width: "60px" }}
                      alt="logo_2"
                    />
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <hr />
                <Offcanvas.Body>
                  <ul className="list-unstyled">
                    <li>Register</li>
                    <li>
                      <Link to="/login">Login</Link>
                    </li>
                    <li>Saved</li>
                    <li>FeedBack</li>
                    <li>
                      <Link to={termUrl}>Terms and Condition</Link>
                    </li>
                    <li>
                      <Link to={privacyUrl}>Privacy Policy</Link>
                    </li>
                  </ul>
                </Offcanvas.Body>
              </Offcanvas>
            </Col>
            <Col xs={8} sm={8} md={8}>
              <input
                type="text"
                name="myInput"
                id="textName"
                placeholder="Search"
                value={word || amenityWord}
                // onFocus={() => setBooleanValue(true)} // Set boolean value to true when input is focused
                onBlur={(e) => {
                  if (e.target.value === "") {
                    setBooleanValue(false); // Set boolean value to false if input box is empty
                  }
                }}
                onChange={(e) => {
                  setListValue(false);
                  autoCompleteFetchData(e.target.value);
                  setWord(e.target.value);
                  setAmenityValue(""); // Clear amenityWord when typing
                  if (amenityWord) {
                    setSelectedWord("");
                  }
                  if (e.target.value === "") {
                    setBooleanValue(false); // Set boolean value to false if input box is empty
                  } else if (e.target.value !== "") {
                    setBooleanValue(true); // Set boolean value to true when user starts typing
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    searchIconClickedHandler(word);
                    setData(null);
                  }
                }}
                autoComplete="off"
              />
            </Col>
            <Col
              xs={3}
              sm={3}
              md={3}
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
              // onClick={() => autoCompleteFetchData(word)}
            >
              {/* {selectedWord || isBooleanValue ? (
                <img
                  onClick={() => {
                    handleClear();
                  }}
                  src={close}
                  alt="close"
                />
              ) : (
                <img src={search} alt="search" />
              )} */}
              <img
                src={search}
                alt="search"
                onClick={() => searchIconClickedHandler(word)}
              />
              <div className="vline"></div>
              {/* {direction ? (
                <img
                  onClick={() => {
                    handleClear();
                  }}
                  src={close}
                  alt="close"
                />
              ) : (
                <img
                  src={directions}
                  alt="directions"
                  onClick={() => {
                    handleClick();
                  }}
                />
              )} */}

              {selectedWord || direction || isBooleanValue ? (
                <img
                  onClick={() => {
                    handleClear();
                  }}
                  src={close}
                  alt="close"
                />
              ) : (
                //  <Link to="/directions">
                <img
                  src={directions}
                  alt="directions"
                  onClick={() => {
                    handleClick();
                  }}
                />
                // </Link>
              )}
            </Col>
          </div>
        </div>
      </div>
      <div>
        <Searchpoint
          name={selectedWord} //check for data
          settingZoomLevels={settingZoomLevels}
          longitude={longitude}
          latitude={latitude}
        />
      </div>
      {autoCompleteLists(word, data)}

      {departmentList && departmentList.length > 0 && (
        <div
          className="search-data"
          style={{
            overflowY: departmentList.length > 12 ? "scroll" : "no-scroll",
          }}
        >
          <ul>
            {departmentList.map((item, index) => {
              const { properties, geometry } = item;
              let logoUrl = "";

              // Parse the logo property
              try {
                const logos = JSON.parse(JSON.parse(properties.logo));
                logoUrl = logos[0].image;
              } catch (e) {
                console.error("Error parsing logo JSON:", e);
              }

              return (
                <li
                  key={index}
                  onClick={() => handleListClick(properties, geometry)}
                >
                  <Row
                    style={{
                      display: "flex",
                      paddingBottom: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <Col
                      md={2}
                      xs={3}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {logoUrl && (
                        <img
                          src={`${imageUrl}${logoUrl}`}
                          alt={properties.searchedItem}
                          style={{ maxWidth: "100%" }}
                        />
                      )}
                    </Col>
                    <Col
                      md={10}
                      xs={9}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {properties.searchedItem},&nbsp;
                        {properties.municipality}-{properties.ward}
                      </span>
                    </Col>
                  </Row>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {selectedItem && (
        <Departmentlist
          name={selectedItem.properties.searchedItem}
          municipality={selectedItem.properties.municipality}
          ward={selectedItem.properties.ward}
          longitude={selectedItem.geometry.coordinates[0]}
          latitude={selectedItem.geometry.coordinates[1]}
        />
      )}
      {direction && (
        <div>
          <Direction />
        </div>
      )}
    </>
  );
};

export default Autocomplete;
